import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUtils';
import { ClientUserContext } from '../context/ClientUserContext'; // Import ClientUserContext
import './CreateClientJob.css';


const CreateClientJob = () => {
    const [error, setError] = useState(null); // New state for error handling
    const [errors, setErrors] = useState({}); // New state for error handling
    const [formData, setFormData] = useState({
        jobTitle: '',
        jobDesc: '',
        jobRequirements: '',
        jobCVRequired: 'false',
    });
    const navigate = useNavigate();
    const { clientUser } = useContext(ClientUserContext); // Access client user details from context

    if (error) {
        console.log("Error: ", error);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        if (name === 'jobTitle') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                jobTitle: value.length !== 0 ? '' : 'Job Title cannot be empty.'
            }));
        } else if (name === 'jobDesc') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                jobDesc: value.length !== 0 ? '' : 'Job Description cannot be empty.'
            }));
        }
    }

    const handleCreateJob = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch(getApiUrl("/api/client-jobs"), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    client_user_id: 3,
                    job_title: formData.jobTitle,
                    job_description: formData.jobDesc,
                    status: 'Closed',
                })
            });
            
            if(res.ok) {
                const data = await res.json();
                const screeningRes = await fetch(getApiUrl("/api/client-job-screening-requirements"), {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        job_id: data.id,
                        cvUploadRequired: formData.jobCVRequired === 'true',
                        cvScreeningInstructions: formData.jobRequirements
                    })
                });

                if(screeningRes.ok) navigate(`${data.id}/interviews`);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    return (
        <div className="create-job-container">
            <header className="create-job-navbar popins-regular">
                <Link to="/" className="logo">
                    <span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span>
                </Link>
                <div>
                    <h2>Welcome, </h2>
                    <h2>{clientUser.user_name}</h2> {/* Display user name from context */}
                </div>
            </header>
            <main className="create-job-main">
                <div className="poppins-regular">
                    <h2>Create a new Job</h2> {/* Replace "Your Job Listings" with client's name */}

                    <form className='job-info' onSubmit={handleCreateJob}>
                        <div className="form-group title">
                            <input
                                type="text"
                                name="jobTitle"
                                value={formData.jobTitle}
                                onChange={handleChange}
                                required
                            />
                            <label className={formData.jobTitle ? 'filled' : ''}>Job Title*</label>
                            {errors.jobTitle && <p className="error-text">{errors.jobTitle}</p>}
                        </div>
                        <div className="form-group cv-select">
                            <select
                                name="jobCVRequired"
                                value={formData.jobCVRequired}
                                onChange={handleChange}
                                required
                            >
                                <option value='false'>False</option>
                                <option value='true'>True</option>
                            </select>
                                <label className={formData.jobCVRequired ? 'filled' : ''}>CV Upload Required*</label>
                            {errors.jobCVRequired && <p className="error-text">{errors.jobCVRequired}</p>}
                        </div>
                        <div className="form-group">
                            <textarea
                                type="text"
                                name="jobDesc"
                                value={formData.jobDesc}
                                onChange={handleChange}
                                required
                            >
                            </textarea>
                            <label className={formData.jobDesc ? 'filled' : ''}>Job Description*</label>
                            {errors.jobDesc && <p className="error-text">{errors.jobDesc}</p>}
                        </div>
                        {
                            formData.jobCVRequired === 'true' && (
                                <div className="form-group">
                                    <textarea
                                        type="text"
                                        name="jobRequirements"
                                        value={formData.jobRequirements}
                                        onChange={handleChange}
                                        required
                                    >
                                    </textarea>
                                    <label className={formData.jobRequirements ? 'filled' : ''}>CV Screening Instructions*</label>
                                    {errors.jobRequirements && <p className="error-text">{errors.jobRequirements}</p>}
                                </div>
                            )
                        }
                        <button className='primary' type='submit'>Create Job</button>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default CreateClientJob;